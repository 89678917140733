.add-information-field {
  .modal-header {
    background-color: #272727;
    padding: 0.25rem 1rem;
    border: none;
    border-radius: 18px 18px 0px 0px;

    button {
      color: white;
      opacity: 1;
    }
  }
  .modal-dialog {
    //margin: 3rem 1.5rem;
  }

  .modal-content {
    border-radius: 20px;
    border: 3px solid #5258ec;
  }
}

.add-information-field-options {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #272727;
  color: white;
  border-radius: 0px 0px 18px 18px;

  span {
    margin: 0.75rem 0;
    display: flex;
    align-items: center;
  }

  svg {
    height: 1.5rem;
  }
}
