.register-page {
  margin: 0 5%;
  padding-top: calc(70px + 2rem);
  padding-bottom: 2rem;
  padding-left: 25px;
  padding-right: 25px;

  &-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    &-title {
      font-size: 26px;
      font-weight: 500;
      color: white;
    }

    &-subtitle {
      font-size: 14px;
      color: white;
    }
  }

  .register-form {
    input {
      color: lightgrey;
      background-color: #464646 !important;
      border-radius: 6px !important;
      border-width: 2px !important;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &:focus {
        color: lightgrey !important;
        box-shadow: none;
        border: double 2px transparent;
        border-radius: 6px !important;
        background-image: linear-gradient(#464646, #464646), 
                          linear-gradient(to bottom, #567df4, #6200ee);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    }

    .input-group-append {
      padding: 10px;
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 10;
    }
  }

  label {
    margin-bottom: 0rem !important;
    margin-bottom: 5px !important;
  }
  input {
    height: 3rem;
  }

  .register-page-create-account__btn__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .register-create-account {
      background-color: #567df4;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      border-radius: 12px;
      text-decoration: none;
      width: 48%;
      margin-right: 0.75rem;
      cursor: pointer;

      svg {
        margin-left: 10px;
      }
    }
  }

  .disclaimer {
    padding-top: 0.75rem;

    .disclaimer-content {
      color: white;
      font-size: 14px;

      &>input {
        height: auto !important;
      }

      .italic {
        font-style: italic;
      }
    }
  }
  .background-upload-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .6);
    .spinner-border {
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
    }
  }
}

