#lottie{
  background-color:#151515 !important;
  width:100vw;
  height:100vh;
  padding: 20vh 20vw;
  display:block;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0,0,0);
  text-align: center;
  opacity: 1;
  z-index: 1001;
}
