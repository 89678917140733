.socials__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  a {
    margin: 0 2rem;
    svg, path {
      fill: white;
    }
  }
}
