.manage-subscription-content-modal {
  background-color: #1D1D1F !important;
  border-radius: 30px !important;
  margin: 10px;
  height: 300px !important;
  display: flex;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-modal-body{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.displayFlex {
  display: flex;
  margin-bottom: 10px;
}

.subscription-btn {
  text-align: center;
  border-radius: 15px !important;
  font-size: 16px !important;
  width: 100% !important;
  height: 60px;
  font-family: 'Poppins';
  font-weight: 600 !important;
  padding: 0 !important;
  line-height: 20.86px;
  margin: 0;
  color: #fff;
}

.subscription-second-btn {
  text-align: center;
  border-radius: 13px !important;
  font-size: 16px !important;
  width: 100% !important;
  height: 40px;
  font-family: 'Poppins';
  font-weight: 600 !important;
  line-height: 20.86px;
  margin: 0;
  padding: 0 !important;
  color: #fff;
  &.pause {
    height: 60px;
  }
}

.subcription-cancel{
  background: none;
  border: none;
}

.subscription-text {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 20.86px;
  margin: 0 !important;
  color: #fff;
  text-align: center;

  &.right {
    margin-right: 5px;
  }

  &.lightColor {
    color: #EAEAEA !important;
  }

  &.blueColor {
    color: #2E81FF !important;
  }

  &.redColor {
    color: #BF3131 !important;
  }

  &.light {
    font-weight: 400;
  }

  &.veryLight {
    font-weight: 300;
  }
  &.bottom {
    margin-bottom: 10px;
  }

  &.smallFont {
    font-size: 14px;
  }

  &.removeLineHeight {
    line-height:0 !important
  }

  &.mediumFont {
    font-size: 16px;
  }

  &.heading {
    font-size: 20px;
  }
  &.underline {
    text-decoration: underline;
  }
}

.bFdOln {
  height: 450px !important;
}
