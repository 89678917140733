.subscribption-main {
  min-height: 100vh;
  padding-top: 4.21rem !important;
}

.subscribption {
  justify-content: center;
  align-items: center;
  padding: 2px 30px 30px 30px;
  background: linear-gradient(#1D1D1F, #0F0F43);

  &-container {
    display: flex;
    // padding: 20px;/
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_content {
      display: flex;
      flex-direction: row;
    }
  }
}

.subs-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #FBFBFB;

  &-box {
    display: flex;
    flex-direction: row;

    &_circle {
      color: rgb(28, 150, 221);
      height: 18px;
      width: 18px;
      margin: 5px;
      margin-right: 10px;
    }

    &_text {
      font-size: 24px;
      font-weight: 600;
      line-height: 25.03px;
    }
  }
}

.displayFlex {
  display: flex;
  justify-content: center;
  align-items: center;

  &.last {
    margin-bottom: 20px;
  }

  &.head {
    margin-top: 20px;
  }

  &.flexStart {
    justify-content: flex-start;
  }

  &.alignItemStart {
    justify-content: flex-start;
  }

  &.column {
    flex-direction: column;
  }

  &.space_between {
    justify-content: space-between;
  }

  &.space_around {
    justify-content: space-around;
  }
}

.payment-method-container {
  width: 100%;
  height: 64px;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 1px solid #8E9195;

  &.selected {
    border: 3px solid #2E81FF;
  }
}

.payment_types {
  width: 48%;
  height: 64px;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid #8E9195;

  &.selected {
    border: 3px solid #2E81FF;
  }
}

.normal-text {
  font-size: 20px;
  color: #FBFBFB !important;
  margin: 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  &.bold {
    font-weight: 600 !important;
  }

  &.heading {
    margin-top: 35px;
  }

  &.sub-heading {
    margin-top: 20px;
  }

  &.main-heading {
    margin-top: 55px;
  }

  &.light-color {
    color: #93A1A9;
  }

  &.bottom {
    margin-bottom: 35px;
  }

  &.light {
    font-weight: 400 !important;
  }

  &.medium {
    font-size: 15px;
  }

  &.small {
    font-size: 8px;
  }

  &.alignText {
    text-align: left;
  }

}

.footer-text {
  font-size: 11px;
  color: #828A9A;
  margin: 0;
  text-align: center;
  flex-direction: row;
  font-family: 'Poppins', sans-serif;
}

.icon-container {
  margin-right: 10px;
  width: 35px;
  color: #FBFBFB;
  height: 35px;
}

.react-btn {
  height: 60px;
  border-radius: 15px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 35px;
}

.back-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.back-btn {
  color: white;
  align-self: center;
  position: absolute;
  left: 30px;
  cursor: pointer;
}

.background-upload-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .6);
  .spinner-border {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
  }
}