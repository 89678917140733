@import '../../styles/theme.scss';

.edit-profile-page {
  display: flex;
  flex-direction: column;

  &.profile-page {
    padding: 0;

    &#view-profile-page {
      position: relative;

      .hide-tappy-footer {
        display: none;
      }
    }

    &.light {
      background-color: $theme-light-background;

      .container {
        // @include light-container('primary');
        // background-color: $theme-light-block;
        color: $primary-black !important;
        // border-bottom: 3px solid #3B2C2C;
      }

      .profile-declaration {
        color: $primary-black !important;
      }

      .profile-name {
        color: $secondary-black;
      }

      .profile-social-username {
        color: $secondary-black;
        font-weight: bold;
      }

    }

    &.dark {
      background-color: #19191F;

      .profile-social {
        border-radius: $border-radius;
      }
    }


    .container {
      border: none;
      border-radius: 0 0 $border-radius $border-radius;
      margin-bottom: 1rem;
      padding-top: calc(50px + 2rem);

      .profile-name {
        font-weight: 700;
        font-size: 26px;
        width: 100%;
        text-align: center;
      }

      .download-contact-form {
        display: flex;
        justify-content: center;

        button {
          width: 85%;
          border: 1px solid;
          border-radius: 10px;
          padding: 6px 0;
        }
      }
    }

    .profile-header-container {
      z-index: 999;
      border-radius: 12px;
      border-color: rgba(255, 255, 255, 0);
    }

    .profile-container {
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: calc(100% + 4px);
      padding-bottom: 0 !important;
      margin-bottom: 0;

      .form-group {
        margin-bottom: 0 !important;
      }
    }
  }

  .container {

    .profile-bio {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 15px;
    }

    .profile-basic-info {
      width: calc(100% - 1rem - 100px);
      padding-left: 15px;

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .profile-name {
          font-size: 26px;
          font-weight: bold;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          text-shadow: 0px 3px 12px #404040;
        }

        .add-to-contact-btn {
          width: 100%;
          height: 35px;
          margin: 0;
          position: relative;
          display: block;
          color: white;
          background-color: #5258ec !important;
          border-radius: 10px !important;
        }
      }
    }
  }

  .dark-theme-dwn-cotact {
    border: 1px solid;
    width: 75%;
    padding: 5px 0;

    &.sharp {
      border-radius: 0 !important;
    }

    &.rounded {
      border-radius: 9px !important;
    }

    &.circular {
      border-radius: 35px !important;
    }
  }

  .social-items__container {
    flex: 1;
    margin-top: 22px;

    .edit-social-media-profiles {
      padding: 0.5rem 1.25rem;
    }
  }

  #download-trigger {
    z-index: 999;
  }

  .profile-basic-info-section {
    position: absolute;
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .profile-name {
      font-size: 36px !important;
      text-shadow: 0px 3px 12px #404040;
    }
  }

  .profile-declaration {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 50px;
  }

  .tappy-upsell {
    width: 100vw;
    max-width: 500px;
    height: 46px;
    margin: 0;
    display: block;
    color: white;
    background-color: #000;
    position: fixed;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 2.5;
    z-index: 1000;
    transition: all 0.4s ease-in-out;
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    align-items: center;
    justify-content: center;


    &.show {
      bottom: 0;
    }

    &.hide {
      bottom: -50px;
    }

    &.hide-tapy-button {
      position: unset;
    }
  }
}

.video-home-container {
  height: 100vh;
  position: relative;
}

.videoConntainer {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

@media (max-width: 350px) {
  .profile-name {
    font-size: 18px !important;
  }

  .dark-theme-dwn-cotact {
    font-size: 12px;
  }
}

.background-upload-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .6);

  .spinner-border {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
  }
}

.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}