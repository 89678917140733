@import "../../styles/palette.scss";

.main-header {
  background-color: #262628;
  border-radius: 0 0 $border-radius $border-radius;
  height: 70px;
  position: fixed !important;
  width: 100%;
  max-width: unset !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.navbar {
  justify-content: space-between !important;
}

.navbar-brand {
  height: 55%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
}

.navbar-toggle {
  border: none !important;
  margin-right: 25%;
  cursor: pointer;
}

.App {
  .nav-link {
    background-color: transparent !important;
    border-color: white;
    padding-top: 1.8px !important;
    padding-bottom: 2.5px !important;
    font-weight: bold !important;
    font-size: 0.8rem !important;
  }
}

#share-button {
  font-size: 14px;
  position: relative;
}

#link-button {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border: none !important;
  border-radius: 15px;
  height: 35px;
  font-size: 1.4rem !important;
}

.main-header {
  .hamburger-icon {
    fill: white;
    width: 26px;
    height: 24px;
  }
}

.openNavBar {
  .modal-dialog {
    margin: 1rem;
  }

  .tappy-logo {
    height: 30px;
  }

  .modal-content {
    background-color: #272727 !important;
    color: lightgrey !important;
    box-shadow: none;
    overflow: hidden;
    border: double 3.5px transparent;
    border-radius: 12px !important;
    background-image: linear-gradient(#272727, #272727),
      linear-gradient(to bottom, #567df4, #6200ee);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 310px;
  }

  .profile-declaration {
    font-size: 11px;
    text-align: center;
  }

  .modal-body {
    padding: 0 1rem;
    background-color: #1D1D1F;

    &>a {
      display: block;
      color: white !important;
      margin: 24px 10px;
      padding: 0;
      letter-spacing: 0.15px;
      font-weight: 700;
      cursor: pointer;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .modal-header {
    border: none;
    flex-direction: row;
    background-color: #1D1D1F;

    a {
      height: 90%;
      flex: 1;
      display: flex;

      img {
        height: 85%;
      }
    }

    button.close {
      display: flex;
      width: 33px;
      height: 33px;
      margin: 5px;
      border-radius: 50%;
      background-color: #D1D1D1;
      opacity: 1;
      margin: 0;
      padding: 0;
      border: 0;
      align-self: center;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      span {
        text-shadow: none;
        height: 100%;
        font-weight: normal;
        font-size: 30px;
      }

      span.sr-only {
        display: none;
      }
    }
  }

  .modal-footer {
    border: none;

  }

}

.shop-tappy-btn {
  width: 259px;
  height: 36px;
  border-radius: 8px !important;
  background-color: #2E81FF !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
  border: 0 !important;
  padding: 0;
  &.get {
    background-color: #193052 !important;
  }

  &:focus {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.8) !important;
  }
}

.shop-tappy-footer {
  color: #E4E4E4;
  font-size: 17px;
  font-weight: 700;
  padding: 0;
  text-decoration-line: underline;
  text-decoration-color: #fff;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  margin: 0;
  margin-bottom: 10px;

  &.support {
    margin-top: 12px;
  }
}

.shop-tappy-footer-social-icons-container {
  display: flex;
  width: 38%;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 20px;
}

.logout-btn {
  font-size: 15px !important;
}

.shop-tappy-footer-border {
  border-bottom: 2px solid #193052;
  margin-bottom: 25px;
  width: 140%;
  margin-left: -20%;
}


.openQRCode {
  position: absolute;
  opacity: 1;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  max-width: unset !important;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;

  &.hidden {
    display: none;

    .modal-content {
      opacity: 0;
    }
  }

  &.show {
    display: flex;

    .modal-content {
      opacity: 1;
    }
  }


  .tappy-logo {
    height: 30px;
    position: absolute;
  }

  .modal-content {
    background-color: #272727 !important;
    color: lightgrey !important;
    box-shadow: none;
    border: double 3.5px transparent;
    border-radius: 12px !important;
    background-image: linear-gradient(#272727, #272727),
      linear-gradient(to bottom, #567df4, #567df4);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    min-height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    pointer-events: auto;
    outline: 0;
    transition: all 0.4s ease-in-out;
  }

  .modal-body {
    padding: 1rem;

    &>a {
      display: block;
      color: white !important;
      background-color: white;
      padding: 15px;
      letter-spacing: 0.15px;
      border-radius: 10px;
      font-weight: 700;
      cursor: pointer;
      font-size: 18px;
      line-height: 30px;
    }
  }

  .modal-header {
    border: none;
    height: 70px;
    flex-direction: row-reverse;

    a {
      width: calc(100% - 50px);
      height: 90%;
      padding-right: 36px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: 90%;
      }
    }

    button.close {
      display: flex;
      width: 35px;
      height: 35px;
      margin: 5px;
      background-color: #D1D1D1 !important;
      border-radius: 50%;
      margin: 0px;
      padding: 0;

      span {
        text-shadow: none;
        width: 100%;
        height: 100%;
        font-weight: normal;
        font-size: 30px;
      }

      span.sr-only {
        display: none;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 0;

    a {
      margin: 0 0 1rem 0;
    }

  }

  .modal-footer {
    border: none;
  }

}