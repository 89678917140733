.register__btn {
  padding: 1rem 0;
  border-radius: 12px;
  text-decoration: none;
  background-color: rgba(86, 125, 244, 0.1);
  color: #567df4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
}
