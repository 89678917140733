.spark-main-modal-content {
  border-radius: 20px !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spark-main-modal-body {
  padding: 20px !important;
  max-height: 586px;
  max-width: 411px;
  overflow: auto;
}

.main-modal_tag {
  font-size: 20px;
  color: #FBFBFB !important;
  margin: 0;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600 !important;
}

.closed-icon {
  position: 'absolute';
  cursor: pointer;
  margin: 8px;
}

.main-body {
  justify-content: center;
  align-items: center;
  background: linear-gradient(#1D1D1F, #0F0F43);
  border-radius: 39px;
  max-width: 411px;
  max-height: 586px;
  padding: 21px;
  padding-top: 4%;
  padding-bottom: 2%;

  &-spark-btn {
    height: 60px;
    border-radius: 15px !important;
    width: 100%;
    display: flex;
    font-size: 24px;
    font-family: 'Poppins';
    margin-bottom: 1rem;
  }
}

.connect-tappy {
  &_first__form {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;
    justify-content: space-between;

    input {
      background-color: #31405E;
      width: 94%;
      max-height: 41px;
      border: 0;
      color: lightgrey;
      border-radius: 10px;
      font-family: 'Poppins';

      &:focus {
        color: lightgrey !important;
        border: 2px solid #5258ec;
        background-color: #31405E;
      }
    }

    label {
      margin-bottom: 0.25rem;
      color: white;
      font-family: 'Poppins';
    }
  }
}

.connect-tappy {
  &__form {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;

    input {
      background-color: #31405E;
      border: 0;
      width: 98%;
      max-height: 41px;
      color: lightgrey;
      margin-bottom: 1rem;
      border-radius: 10px;
      font-family: 'Poppins';

      &:focus {
        color: lightgrey !important;
        border: 2px solid #5258ec;
        background-color: #31405E;
      }
    }

    label {
      margin-bottom: 0.25rem;
      color: white;
      font-family: 'Poppins'
    }
  }
}

.spark-modal-footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.04px;
  font-family: 'Poppins';
  text-align: center;
  color: #B1B1B1;
  margin-bottom: 1rem;
}

.main-modal-text {
  font-size: 24px;
  line-height: 37.03px;
  text-align: center;
  color: white;
  margin-top: 50%;
  font-family: 'Poppins';
}

.main-body-spark-btn {
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}

.phone-input-box {
  background-color: #31405E !important;
  border: none !important;
  border-radius: 10px !important;
  width: 100% !important;
}

.phone-button-box {
  background-color: #31405E !important;
  border: none !important;
  border-radius: 10px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #31405E !important;
  border-radius: 10px !important;
}

.react-tel-input .selected-flag:hover {
  background-color: #31405E !important;
  border-radius: 10px !important;

}