.button-container {
    .container-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    
        .button-shape-container {
            background-color: #9B9B9B;
            border: 1px solid #9D9D9D;
            width: 100%;
            height: 50px;
            cursor: pointer;

            &.rounded-button {
                border-radius: 9px;
            };
            &.circular-button {
                border-radius: 35px;
            };
            &.button-design-radius {
               border: 4px solid #0A5EAC !important; 
            }
        };
        .button-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        };
        .edit-button-header {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px
        }
        .color-input {
            width: 60px;
            height: 60px;
            border: none;
            border-radius: 7px;
            cursor: pointer;
        }
        .opacity-container {
            width: 60px;
            height: 60px;
            background: linear-gradient(224.55deg, rgba(0, 0, 0, 0.01) 4.2%, #E3E3E3 100%);
            border: none;
            border-radius: 7px;
            cursor: pointer;
            border: 1px solid #555555;
        }
        .color-container {
            width: 60px;
            height: 60px;
            border: none;
            border-radius: 7px;
            cursor: pointer;
            border: 1px solid #555555;
        }
    };
};
.alpha-picker-container {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    text-align: center;
    .opacity-text {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
    }
    .alpha-picker {
        max-width: 185px !important;
    }
}