.edit-profile-page {
  padding-top: 70px;
  padding-bottom: 50px;
  min-height: 100vh;

  &__view {
    color: white !important;
    border: 2px solid white;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.25rem;
  }

  form {
    button {

      &.edit-profile-upload-photo__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -2px;
        right: -16px;
        width: 50px;
        height: 50px;
        z-index: 9;
      }
    }
    .profile-tabs {
      border: none;
      display: flex;
      justify-content: space-around;
      padding-top: 28px;
      .nav-link {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px;
        color: #ffffff;
        background-color: #606060 !important;
        border: 2px solid #ae4545;
        border-color: #A3A3A3 !important;
        border-radius: 11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        &.active {
          background: linear-gradient(0deg, #0A5EAC, #0A5EAC), linear-gradient(0deg, #0A5EAC, #0A5EAC), #0A5EAC;
          border: none;
        }
        @media (max-width: 375px){ 
          padding: 10px;
        }
      }
    }
  }

  input {
    border: none;
  }

  &-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 154.3%;
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > a {
      font-size: 13px;
      color: #5258ec;
    }
  }
  .background-upload-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .6);
    .spinner-border {
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
    }
  }
}

.edit-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
}

.edit-base-profile {
  display: flex;
  align-items: center;
}
.edite-base-profile-block{
    padding-top: calc(56px + 2rem);
    .edite-base-profile-image{
      background: linear-gradient(
180deg
, rgba(171, 171, 171, 0) 57.33%, rgba(0, 2, 10, 0.65) 100%), #D9D9D9;
    border-radius: 22px;
      display: flex;
     img{
      object-fit: cover;
      width:100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      border-radius:31px;
      width: 380px;
      height: 370px;
      border:4px solid transparent;
      // background-image: linear-gradient(180deg, rgba(171,171,171,0) 57.33%, rgba(0,2,10,0.65) 100%);
      background-image: linear-gradient(
270deg
, rgba(171, 171, 171, 0) 0.33%, rgba(56, 56, 56, 1) 0%);
     }
    }
    .edite-base-proflie-image-inner{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 22px;
      max-height: 180px;
      min-height: 180px;
      max-width: 180px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin:auto;
      z-index: 999;
      .profile-basic-info{
        margin-bottom: -15px;
      }
    }
    .profile-picture-background {
      width: 190px;
      height: 190px;
      display: flex;
      justify-content: center;
      margin: auto;
      align-items: center;
      background: linear-gradient(199.16deg, #000000 -1.42%, #444444 44.3%, #000000 87.11%);
      border-radius: 26px;
    }
}
.edit-profile__img {
  width: 120px;
  height: 120px;
  margin-right: 0.5rem;
  position: relative;
}

.edit-profile__img .avatar-preloader {
  width: 100%;
  height: 100%;
  background-size: 60% 60%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-animation: spinX 2s infinite;
  animation: spinX 2s infinite;
}

.edit-profile__img > img {
  position: absolute;
  z-index: 2;
}


.edit-profile-edit-contact-card__btn {
  color: #ffffff !important;
  background-color: #1E3448 !important;
  border-radius: 9px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

  &.profile__btn {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 36px;
    width: 100vw;
    max-width: 500px;
    margin: 0;
    color: white;
    background-color: #0A5EAC !important;
    position: fixed;
    bottom: 0;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 2.5;
    z-index: 1000;
    transition: all 0.4s ease-in-out;
    border-radius: 12px 12px 0 0 !important;
    margin-left: -15px;
  }
}

.edit-profile-edit-contact-card__btn {
  width: 75%;
}

.contact-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 38px;
  .tab-content {
    width: 100%;
  }
  .nav-tabs {
    padding: 10px;
    width: 100%;
    justify-content: space-around;
    margin-top: 16px;
    border: 1px solid #EBE7E7;
    border-radius: 34px;
    align-items: center;
    .nav-link {
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 25px;
      padding: 0;
      border: none;
      color: #ffffff !important;
      &.active {
        background-color: #0A5EAC !important;
        border-radius: 10px;
        padding: 5px 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .theme-editor {
    background-color: unset !important;
    .card-body {
      padding: 0 !important;
    }
    .theme-block-select {
      width: 140px !important;
      height: 220px !important;
      margin: 10px !important;
    }
  }
}

.contact-card-section-1 {
  background-color: #1B1B1B;
  border: 1px solid #173A5A;
  border-radius: 16px;
  padding: 16px 20px 6px;
  
  .contact-card-text-wrapper {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    padding-top: 5px;
  }
}

.add-new-tappy__form input {
  color: lightgray;
}

.edit-contact-card__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.enable-contact-card__btn {
  font-weight: bold;
  margin-right: 0.5rem;
}

.contact-card-lable {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.add-profile-prompt {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 20px;
  position: relative;

  .add-profile-prompt-icon {
    position: absolute;
    transform: translateX(10px);
    z-index: 9;
  }
  .add-block-dropdown {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;
  }
}

.add-profile-wrapper {
  .social-rearrange-text {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    padding: 10px 0;
  }
}

.add-profile-header {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 20px;
}

.socials-select::-ms-expand {
  display: none;
}

.socials-select {
  position: relative;
  top: 2.5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  margin: 0 20px;

  .dropdown-menu {
    width: 100%;
    max-height: 400px;
    overflow: overlay;
  }

  .dropdown-toggle {
    background-color: #0A5EAC !important;
    padding: 13px 0 !important;
    border-radius: 32px;
    color: black !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after{
      display: none !important;
    }

    & > span {
      width: calc(100% - 20px);
    }

    &:focus {
      box-shadow: none !important;
    }

    &[aria-expanded="true"] {
      & > svg {
        transform: translateX(-25%) rotate(135deg);
      }
    }

    & > svg {
      position: absolute;
      left: 0;
      transform: translateX(-25%) rotate(0deg);
      transition: all 0.3s ease-in-out;
    }

    &::after {
      display: none;
    }

  }
}

.save-profile__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin: 10px 0;
    background-color: #5258ec !important;
    border-radius: 10px !important;
  }
}

.photo-name-upload {
  width: calc(100% - 120px);
  padding: 0 16px;
  .name-lable {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}

.edit-profile-bio__wrapper {
  padding: 15px;
  label {
    font-weight: 700;
    font-size: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    .lable-span {
      font-weight: 400;
      font-size: 11px;
      line-height: 24px;
    }
  }
  textarea {
    background-color: #353535;
    border: none;
    color: white;
    border-radius: 10px;
  }

  #foo {
    resize: none;
  }
  
  textarea:focus {
    background-color: #353535;
    color: white;
  }
}

@-webkit-keyframes spinX
{  
  0%   {-webkit-transform: rotate(0deg); -webkit-transform-origin: 50% 50%;}  
  100% {-webkit-transform: rotate(360deg); -webkit-transform-origin: 50% 50%;}  
}
@keyframes spinX
{  
  0%   {transform: rotate(0deg); transform-origin: 50% 50%;}  
  100% {transform: rotate(360deg); transform-origin: 50% 50%;}  
}