.main-stripe-modal-content {
  border-radius: 20px !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-stripe-modal-body {
  padding: 0 !important;
  max-height: 650px;
  max-width: 400px;
  border-radius: 30px !important;
  overflow: auto;
}
