.reset-password {
  padding: 6rem 2rem;

  &__form {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 1rem;
      width: 50%;
      border-radius: 12px;
      height: 3.25rem;
      background-color: #567df4;
      border:0;
    }
    span {
      margin-bottom: 0.75rem;
    }

    input {
      color: lightgrey;
      background-color: #464646 !important;
      border-radius: 6px !important;
      border-width: 2px !important;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &:focus {
        color: lightgrey !important;
        box-shadow: none;
        border: double 2px transparent;
        border-radius: 6px !important;
        background-image: linear-gradient(#464646, #464646), 
                          linear-gradient(to bottom, #567df4, #6200ee);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    }
  }

  &__label {
    font-size: 20px;
    font-weight: 500;
  }

  &__username {
    font-size: 14px;
  }

  &__links {
    display: flex;

    button {
      width: 100%;
    }

    a {
      width: 100%;
      margin-top: 1rem;
    }
  }

  &__back {
    margin-top: 5rem;
    width: 100%;
    height: 3.25rem;
    border-radius: 12px !important;

    svg {
      margin-right: 1rem;
    }
  }
}

.reset-password__back {
  background-color: rgba(86, 125, 244, 0.1) !important;
  border: none !important;
  color: #567df4 !important;
}

.background-upload-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .6);
  .spinner-border {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
  }
}