$primary-purple: #5258ec;
$primary-blue: #0A5EAC;
$primary-black: #0A0A0A;
$secondary-black: #4A4A4A;

$text-purple-disabled: #567df4;

$theme-light-background: #D9D9D9;
$theme-light-block: #EDEDED;
$theme-light-border: 1px solid white;


$theme-dark-background:#272727;

$border-radius: 12px;




