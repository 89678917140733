.not-found {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  padding-top: calc(70px + 2rem);

  &__header {
    font-size: 30px;
    font-weight: 500;
  }

  &__subheader {
  }
}
