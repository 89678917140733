.account-settings {
  padding: 3rem 2rem;
  padding-top: 6rem;

  &__header {
    font-size: 20px;
    font-weight: 500;
  }

  &__account-form {
    padding-bottom: 2rem;
    padding-top: 1rem;

    input {
      color: lightgrey;
      background-color: #464646 !important;
      border-radius: 6px !important;
      border-width: 2px !important;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      margin-bottom: 1rem;
      &:focus {
        color: lightgrey !important;
        box-shadow: none;
        border: double 2px transparent;
        border-radius: 6px !important;
        background-image: linear-gradient(#464646, #464646), 
                          linear-gradient(to bottom, #567df4, #6200ee);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    }

    button {
      margin-top: 0.5rem;
      font-size: 14px;
      width: 12rem;
      border-radius: 10px;
      height: 3rem;
      background-color: #5258ec;
      border:0;
    }
  }

  &__account-link {
    display: block;
    width: 100%;
    text-align: right;
  }

}
