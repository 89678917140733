.edit-social {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-social-icon {
  height: 57px;
}

.edit-social-delete__wrapper {
  max-width: 50px;
  display: flex;
  align-items: center;
  z-index: 100;
  padding-left: 5px;
  cursor: pointer !important;
  span {
    font-size: 30px;
  }
}
.edit-social-title {
  position: relative;
  left: 10px;
}

.edit-social-icon__wrapper {
  margin-left: -5px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.edit-social-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.edit-social-arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-social-input-container {
  width: 100%;
}
.edit-social-input-url-container {
  margin-top: 10px;
  overflow: hidden;
}
.edit-social-input-field-container {
  width: 100%;
  height:100%;
  z-index: 2;
  display:flex;
  flex-direction: row;
  align-items: center;
 }

.edit-social-input__wrapper {
  width: 100%;
  display: flex;
  height: 50px;
  border-radius: 10px !important;
  border: 2px solid #595959 !important;
  background-color: #242424 !important;
  flex-direction: row;
  .edit-button {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.url-edit-button {
      top: 10px;
    }
  }
}

.edit-social-input__wrapper input {
  height: 100%;
  color: #d3d3d3!important;
  background-color: #242424!important;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:focus {
    box-shadow: none;
    background-color: #242424 !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}
