.stripe-main {
  padding-top: 4.21rem;
  padding-bottom: 3rem;
  min-height: 100vh;
}
.stripe {
  justify-content: center;
  align-items: center;

  background: linear-gradient(#1D1D1F, #0F0F43);


  &-container {
    display: flex;
    padding: 1.8rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_tag {
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      margin: 0;
      color: #FFFFFF;
      padding: 5px;
    }

    &_head {
      font-size: 1.44rem;
      font-weight: 200;
      line-height: 1.5rem;
      color: white;

      &-bold {
        font-size: 1.92rem;
        font-weight: 700;
        color: #EAEAEA;
        text-align: center;
        line-height: 1.5rem;
      }
    }

  }

  &-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    height: 38px;
    width: 267px;
    border-radius: 16px;
  }

  &-check {
    padding: 0 35px 0 35px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-box {
      display: flex;
      flex-direction: row;
      
      &_circle {
        color: rgb(28, 150, 221);
        height: 18px;
        width: 18px;
        margin: 5px;
        margin-right: 10px;
      }

      &_text {
        font-size: 1.33rem;
        font-weight: 600;
        line-height: 25.03px;
        font-family: 'Poppins', sans-serif;
        color: white;
        &.first{
          margin: 0;
          line-height: 1.3;
          }
      }

      &_subtext {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 25.03px;
        font-family: 'Poppins', sans-serif;
        padding-left: 20%;
        color: white;
      }
    }

    &_priceline {
      font-size: 1.2;
      font-weight: 500;
      line-height: 25.03px;
      font-family: 'Poppins', sans-serif;
      color: white;
    }
  }
}

.stripe_text {
  font-size: 13px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: white;
}

.try-btn {
  height: 60px;
  width: 300px;
  font-weight: 600 !important;
  border-radius: 15px !important;
  margin: 0 !important;
}

.react-btn {
  height: 60px;
  width: 300px;
  font-weight: 600 !important;
  border-radius: 15px !important;
}

.pricelineTag {
  font-size: 1.2;
  font-weight: 500;
  line-height: 25.03px;
  font-family: 'Poppins', sans-serif;
  color: white;
}

.closed-icon {
  position: 'absolute';
  top: 20px;
  cursor: pointer;
}