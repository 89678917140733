.reset-password {
    &__username {
        margin: 1rem 0;
    }

    .input-group-append {
        padding: 10px;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 10;
    }
}

.background-upload-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .6);
    .spinner-border {
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
    }
  }