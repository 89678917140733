@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@400;500&family=Open+Sans&family=Pacifico&family=Poppins&family=Roboto&display=swap');

.text-container {
    .font-col-container {
        display: flex;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .container-col {
        .font-family-container {
            min-width: 107px;
            cursor: pointer;
            width: 107px;
            height: 88px;
            background: #131313;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 15px;
            &.font-container-border {
                border: 2px solid #0A5EAC;
            }
            &.popins-font {
                font-family: 'Poppins', sans-serif;
            }
            &.bebas-font {
                font-family: 'Bebas Neue', cursive;
            }
            &.dosis-font {
                font-family: 'Dosis', sans-serif;
            }
            &.pacifico-font {
                font-family: 'Pacifico', cursive;
            }
            &.roboto-font {
                font-family: 'Roboto', sans-serif;
            }
            &.openSans-font {
                font-family: 'Open Sans', sans-serif;
            }
            .edit-button-header {
                font-size: 30px;
                font-weight: 600;
            }
            .font-text-container {
                font-weight: 600;
                font-size: 16px;
                text-align: center;
            }
        }
        .font-color-header {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }
        .color-input {
            margin-left: 10px;
            width: 60px;
            height: 60px;
            border: none;
            border-radius: 7px;
            cursor: pointer;
        }
    }
    .fontColor-col-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0;
    }
}

.text-color-container {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-left: 5px;
}