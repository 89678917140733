.image-modal-dialog {
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 500px !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  max-height: none !important;
}

.modal-content-container {
  background-color: #080908 !important;
  width: 100% !important;
  max-height: 70% !important;
  position: absolute !important;
  cursor: pointer;
  bottom: 0 !important;
  border-radius: 48px 48px 0 0 !important;
  border: 1px #575757 solid !important;
}

.modal-header-container {
  display: block !important;
  border-bottom: 1px solid #575757 !important;
}

.modal-body-container {
  padding: 0 !important;
}

.upload-icon-container {
  display: flex;
  width: '100%';
  padding-top: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    &#file {
      width: 33.33%;
      position: absolute;
      margin: 0;
      color: transparent;
    }

    &::-webkit-file-upload-button {
      display: none;
    }
  }
}

.upload-icon {
  color: #0A5EAC;
  width: 72px;
  height: 72px;
}

.upload-text {
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-top: 8px;
}

.closed-icon {
  color: #90959A;
  font-size: 2.1rem;
  left: 5%;
  position: absolute;
}

.custom-modal-styling-title {
  color: #F5F5F7;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.image-video-main-container {
  display: flex;
  flex-wrap: wrap;
}

.image-video-inner-container {
  flex: 0 1 33.33%;
}

.delete-icon-container {
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  height: 30px;
  width: 30px;
  z-index: 999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}