
.login {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;

    input {
      color: lightgrey;
      background-color: #464646 !important;
      border-radius: 6px !important;
      border-width: 2px !important;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &:focus {
        color: lightgrey !important;
        box-shadow: none;
        border: double 2px transparent;
        border-radius: 6px !important;
        background-image: linear-gradient(#464646, #464646), 
                          linear-gradient(to bottom, #567df4, #6200ee);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    }

    .form-control {      
      height: 3rem;
    }

    .input-group-append {
      padding: 10px;
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 10;
    }
  }

  &__welcome-back {
    margin-bottom: 2rem;
    font-size: 25px;
    font-weight: 400;
  }
}

.login-call-to-action__wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  a {
    width: 50%;
  }
}

.forgot-password {
  font-style: italic;
  color: #848484;
  padding-top: 1rem;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-account__wrapper {
  margin-top: 4rem;

  .no-account {
    color: #848484;
  }
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to bottom, #567df4, #6200ee);
}
.border-gradient-purple {
  border-image-source: linear-gradient(to bottom, #567df4, #6200ee);
}