@import '../../styles/theme.scss';

.theme-editor {
    overflow: auto;
    background-color: #353535 !important;
    border-radius: $border-radius !important;
    color: white !important;
    font-weight: normal !important;
    width: 100%;
    cursor: pointer;
    overflow: unset !important;

    .theme-editer-arrow {
        display: inline-block;
        padding: 10px 15px 10px 10px;
        position: relative;

        &::before,
        &::after {
            border-right: 2px solid;
            content: '';
            display: block;
            height: 10px;
            margin-top: -8px;
            position: absolute;
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            right: 3px;
            top: 50%;
            width: 0;
        }

        &::after {
            margin-top: -1px;
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .theme-selector {
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;

        .theme-block-select {
            width: 85px;
            height: 100px;
            display: inline-block;
            text-align: center;
            margin: 1rem 1.25rem 25px 5px;

            &.current button {
                border: 3px solid #0A5EAC !important;
            }

            button {
                display: block;
                width: 100%;
                height: 100%;
                overflow: hidden;
                padding: 0;
                border: 0;
                box-shadow: none;
                border-radius: $border-radius;

                &:focus {
                    box-shadow: none;
                }

            }

            label {
                width: 100%;
                font-weight: 600;
                font-size: 16px;
                line-height: 25px;
            }

        }
    }

    .card-title {

        font-weight: normal !important;

        span {
            white-space: nowrap;
            line-height: 35px;
        }

        .toggle-container {
            margin: 0 !important;

            &.light {
                .switch {
                    background-color: white;
                    color: $theme-dark-background;

                    &::after {
                        background-color: $theme-light-background;
                    }
                }
            }

            &.dark {
                .switch {
                    background-color: black;
                    color: white;

                    &::after {
                        background-color: $theme-dark-background;
                    }
                }
            }

            .switch {
                width: 100px;
                height: 35px;
                padding-left: 35px;
                line-height: 35px;
                text-align: center;

                &::after {
                    height: 35px;
                    width: 35px;
                    border: 2px solid grey;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .theme-color-picker {
        .choosepicker {
            background: linear-gradient(269deg, rgba(248, 152, 181, 1) 14%, rgba(244, 144, 212, 1) 17%, rgba(238, 132, 255, 1) 44%, rgba(151, 157, 254, 1) 70%, rgba(100, 182, 255, 1) 57%);
            color: #000;
            padding: 14px 21px;
            border-radius: 7px;
            font-weight: 700;
            line-height: 27px;
            margin-left: 10px;

            &:focus,
            &:active {
                box-shadow: none;
            }
        }

        .theme-button-group {
            label {
                margin: 0 10px;
                width: 63px;
                height: 57px;
                border-radius: 9px !important;
            }

            :nth-last-child(1) {
                background-color: #A0DAD3;
                border-color: #A0DAD3;

                &:active,
                &:focus {
                    background-color: #A0DAD3;
                    border-color: #A0DAD3;
                }
            }

            :nth-last-child(2) {
                background-color: #AF48C9;
                border-color: #AF48C9;

                &:active,
                &:focus {
                    background-color: #AF48C9;
                    border-color: #AF48C9;
                }

            }

            :nth-last-child(3) {
                background-color: #5258EC;
                border-color: #5258EC;

                &:active,
                &:focus {
                    background-color: #5258EC;
                    border-color: #5258EC;
                }
            }

            :nth-last-child(4) {
                background-color: #000;
                border-color: #000;

                &:active,
                &:focus {
                    background-color: #000;
                    border-color: #fff;
                }
            }

            .btn-primary:not(:disabled):not(.disabled).active:nth-last-child(4) {
                background-color: #000;
                border: 2px solid #fff;
            }

            .btn-primary:not(:disabled):not(.disabled).active:nth-last-child(3) {
                background-color: #5258EC;
                border: 2px solid #fff;
            }

            .btn-primary:not(:disabled):not(.disabled).active:nth-last-child(2) {
                background-color: #AF48C9;
                border: 2px solid #fff;
            }

            .btn-primary:not(:disabled):not(.disabled).active:nth-last-child(1) {
                background-color: #A0DAD3;
                border: 2px solid #fff;
            }

            .btn-primary:not(:disabled):not(.disabled):active:focus {
                box-shadow: none !important;
            }
        }

        .theme-button-group {
            &:active {
                background-color: transparent;
            }
        }

        .bs-popover-bottom {
            border-radius: 8px;

            .react-colorful {
                padding: 5px;

                .react-colorful__pointer {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    @media (max-width: 360px) {
        .theme-button-group {
            label {
                width: 55px !important;
                height: 46px !important;
            }

            .theme-editer-arrow,
            .toggle-select-title {
                font-size: 15px !important;
            }
        }
    }
}

.theme-live-preview {
    width: 165px;
    height: 272px;
    background: #D9D9D9;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin: 16px 0;
}

.theme-live-preview-dark {
    width: 165px;
    height: 272px;
    background: linear-gradient(180deg, #EDEDED 0%, #1E1E1E 0.01%, #080908 100%);
    border-radius: 23px;
    margin: 16px 0;
}

.theme-live-preview-theme-2 {
    width: 165px;
    height: 272px;
    background: linear-gradient(180deg, #00BBF7 0%, #30FFA8 100%);
    border-radius: 23px;
    margin: 16px 0;
}

.button-live-preview-sharp {
    border-radius: 0 !important;
}

.button-live-preview-rounded {
    border-radius: 9px !important;
}

.button-live-preview-circular {
    border-radius: 35px !important;
}

.live-preview-container {
    .row-preview-container {
        position: relative;
        z-index: 99;
        padding-bottom: 10px;
        padding-top: 16px;
        height: 130px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 18px;
    }

    .preview-text-col-container {
        flex-direction: column;

        button {
            padding: 2px 8px;
            font-size: 10px;
        }
    }

    .live-preview-inner-container {
        padding-top: 18px !important;
        z-index: 99;
        position: relative;

        .profile-pic-container {
            width: 66px;
            height: 66px;
            display: flex;
            justify-content: center;
            margin: auto;
            align-items: center;
            background: linear-gradient(199.16deg, #000 -1.42%, #444 44.3%, #000 87.11%);
            border-radius: 15px;

            .profile-picture-background {
                display: flex;
                justify-content: center;
            }
        }

        button {
            padding: 4px 8px;
            font-size: 12px;
        }
    }

    .profile-bio-preview {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .social-link-container {
        background: rgba(31, 35, 41, 0.6);
        border: 1px solid #000;
        border-radius: 8px;
        height: 30px;
        margin-left: 0;
        padding-left: 28px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.profile-Background-Image,
.linear-gradient-background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.7;
    border-radius: 26px;
}

.live-theme-layout-container {
    background-color: #D9D9D9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.live-theme-layout-header-container {
    height: 35%;
    width: 100%;
    padding: 8px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.current {
        border-radius: 12px;
        background-color: rgba($color: #000000, $alpha: 0);
        flex-direction: column;
        height: 45%;
    }
}

.live-theme-layout-bottom-container {
    height: 60%;
    width: 100%;
    display: flex;
    padding: 8px;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 10%;
}

.live-theme-layout-socials {
    background-color: #4A4A4A;
    width: 100%;
    height: 20px;
    border-radius: 4px;
}

.live-theme-layout-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #4A4A4A;

    &.current {
        border-radius: 12px;
    }
}

.live-theme-layout-profile-details {
    width: 60px;
    height: 15px;
    display: flex;
    align-self: center;
    border-radius: 4px;
    background-color: #4A4A4A;
    &.current {
    width: 80px;

    }
}