.profile-setting-col-container {
    .toggle-container {
        padding: 50px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .switch-toggle-lable-header {
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            padding-right: 15px;
            color: #FFFFFF;
        }
        .toggleActive {
          display: flex;
        }
        .active, .deactive {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #37FFAB;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          padding-right: 12px;
        }
        span {
          font-size: 0.85rem;
        }
      
        .switch {
          position: relative;
          display: inline-block;
          width: 51px;
          height: 29px;
          background-color: #D9DBE9;
          border-radius: 40px;
          transition: all 0.3s;
          margin-bottom: 0;
        }
        .switch::after {
          content: "";
            position: absolute;
            width: 28px;
            height: 28px;
            border-radius: 40px;
            background: #FCFCFC;
            border: 2px solid #D9DBE9;
            transition: all 0.3s;
        }
      
        .checkbox:checked + .switch::after {
          left: 22px;
        }
        .checkbox:checked + .switch {
          background-color:#0A5EAC;
        }
        .checkbox {
          display: none;
        }
        .togglePrivacy {
          display: flex;
        }
      }
}

.banner-text{
  margin: 0;
  padding: 0;
  font-size: 19px;
  color: white;
  font-family: 'Poppins';
  &.top {
    margin-top: 35px !important;
  }
  &.bold{
    font-weight: 700;
  }
  &.light{
    font-weight: 400;
  }
  &.small {
    font-size: 15px;
  }
  &.medium {
    font-size: 17px;
  }
  &.center {
    text-align: center;
  }
}

.pro-feature-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #292929;
  width: 120%;
  margin-left: -10%;
  padding: 10px;
  overflow: hidden;
}

.upload-btn-container{
  width: 100%;
  background-color: #181818;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
  overflow: hidden;
  &.transparent {
    background-color: transparent;
  }
}

.edit-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.upload-btn {
  background-color: #2E81FF;
  padding: 2px 12px;
  border-radius: 5px;
  cursor: pointer;
  &.top{
    margin-top: 5x;
  }
  &.red {
    background-color: #AC2C2C;
  }
  &.active {
    background-color: #4E4F51;
  }
}
