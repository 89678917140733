.dashboard-main-container {
  width: 100%;
  padding: 21px 0px;
}

.dashboard-heading-container {
  background-color: rgba(38, 38, 40, 1);
  padding: 0 20px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 10px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.edit-profile-container {
  display: flex;
  margin: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 260px;
}

.edit-inner-profile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

.commonContainer {
  display: flex;
  border-radius: 12px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap !important;
  background-color: #2C2C2C;
  padding: 15px;

  &.columnDirection {
    flex-direction: column;
  }

  &.alignCenter {
    justify-content: center;
    align-items: center;
  }

  &.marginGap {
    margin: 30px 15px;
  }

  &.marginBottom {
    margin-bottom: 30px;
  }

  &.marginTop {
    margin-top: 10px;
  }

  &.marginRight {
    margin-right: 15px;
  }
}

.common-text {
  font-size: 1.24rem;
  font-family: 'Poppins';
  color: white;
  text-align: left !important;
  margin: 0 !important;
  padding: 0 !important;

  &.heading {
    font-size: 1.55rem;
    font-weight: 700;
  }

  &.bold {
    font-weight: 600;
  }

  &.medium {
    font-size: 1.0rem;
  }

  &.blackColor {
    color: black !important;
  }

  &.leftSpace {
    margin-left: 15px !important;
  }

  &.subHeading {
    font-size: 1.86rem;
  }

  &.btn {
    background-color: #0A5EAC;
    padding: 8px !important;
    color: white !important;
    font-size: 0.90rem !important;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.title {
    font-size: 0.93rem;
  }

  &.marginTop {
    margin-top: 10px !important;
  }
}

.tappy-container {
  padding: 30px 0.93rem;
  overflow-x: auto;
  white-space: nowrap;
}

.tappy-inner-container {
  display: inline-flex;
  white-space: nowrap;
  overflow-x: auto;
}

.tappy-card-container {
  flex: 0 0 auto;
  margin-right: 20px;
  width: 155px;
  height: 150px;
  padding: 10px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tappy-container::-webkit-scrollbar {
  height: 0px !important;
}

.tappy-card-dot {
  width: 80px;
  height: 60px;
  border-radius: 15px;

  &.round {
    width: 60px !important;
    border-radius: 100px !important;
  }
}