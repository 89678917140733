@import "../../styles/palette.scss";

.edit-profile-upload-photo__btn {
  margin-top: 0.5rem;
  background-color:  $primary-blue !important;
  border-radius: 50% !important;
}

.image-upload-input {
  display: none;
}
