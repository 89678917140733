
.edit-contact-card__form {
  .add-information-field__btn {
    width: 100%;
    border-radius: 10px;
    background-color: #0A5EAC;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: row;
    align-items: center;
    border: 1px solid #FFFFFF !important;
    padding: 9px;

    svg {
      position: absolute;
      left: -10px;
    }
    .add-block-dropdown {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }

  .form-group {
    .security-field {
      font-weight: 700;
    }
    label {
      margin-bottom: 0.1rem;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .edit-contact-card__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }
}

.edit-contact-card-btn__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  background-color: #272727;

  .save-contact-card-btn {
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: #0A5EAC;
    border: none !important;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    padding: 8px;
  }
  
  .cancle-contact-card-btn {
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: #5A5A5A;
    border: none !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    padding: 3px;
  }

  button {
    margin-bottom: 1rem;
    border-radius: 10px;
    font-weight: 500;
    border: none !important;
  }
  button:not(.btn-danger) {
    background-color: #5258ec;
  }
}

.edit-contact-card-content {
  padding: 0rem 2rem;
  background-color: #272727;
  color: white;

  input {
    background-color: #464646 !important;
    color: white;
    border: none;
    border-radius: 0.25rem;

    &:focus {
      color: lightgrey;
      background-color: #464646 !important;
      border: 2px solid #5258ec;
    }
  }
}

.remove-field {
  color: white;
  border-radius: 50%;
}

.info-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.react-datepicker__input-container, .react-datepicker-wrapper {
  height: calc(1.5em + .75rem + 2px);

  input {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
   }
}
.remove-field {
  margin-left: 9px;
}

.edit-contact-card-security {
  display: flex;
  flex-direction: column;
  .security-pin-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .security-optional {
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .secure-contact-card {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .input-group-append {
    position: absolute;
  }
  .toggle-container {
    display: flex;
    align-items: center;
  }
}

.edit-contact-card {

  .modal-header {
    background-color: #272727;
    padding: 0.25rem 1rem;
    border: none;
    button {
      color: white;
      opacity: 1;
      margin: 0 !important;
      padding: 0.5rem !important;
      color: black;

      span:first-child {
        text-shadow: none;
        width: 35px;
        height: 35px;
        font-weight: normal;
        font-size: 30px;
        background-color: #E0E0E0;
        border-radius: 50%;
        display: block;
        // justify-content: center;
        color: #252525;
      }
    }
  }

  .modal-dialog {
    border: 4px solid #0A5EAC;
    border-radius: 20px;
    overflow: hidden;
  }
}

.optional-security-input {
  display: flex;
  flex-direction: row;
  width: 50%;
  position: relative;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    border-radius: 0.25rem;
    padding-right: 28px;
  }

  .input-group-append {
    cursor: pointer;
    display: unset;
    position: absolute;
    right: 6px;
    width: 18px;
    height: 18px;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.toggle-container {
  padding-left: 10px;
  color: red;
  .switch-toggle-lable {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding-right: 10px;
  }
  .toggleActive {
    display: flex;
  }
  .active, .deactive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #37FFAB;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-right: 12px;
  }
  span {
    font-size: 0.85rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 29px;
    background-color: red;
    border-radius: 40px;
    transition: all 0.3s;
  }
  .switch::after {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 40px;
    background-color: white;
    border: 2px solid #2D9CDB;
    transition: all 0.3s;
  }

  .checkbox:checked + .switch::after {
    left: 22px;
  }
  .checkbox:checked + .switch {
    background-color:#0A5EAC;
  }
  .checkbox {
    display: none;
  }
  .togglePrivacy {
    display: flex;
  }
}
@import "~react-datepicker/dist/react-datepicker.css"
