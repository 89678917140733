.payment-method-inner-container {
  // width: 47%;
  width: 100%;
  height: 64px;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 1px solid #8E9195;
  &.selected {
  border: 3px solid #2E81FF;
  }
}

.stripe-btn-container{
  width: 100%;
  margin-top: 30px;
  margin-bottom: 25px;
}

.icon-main-container {
  width: 35px;
  height: 24px;
  color: white;
}

.apple-btn-container {
  width: '100%';
  height: 142px;
  background-color: #31405E;
  border-radius: 10px;
  margin-top: 10px;
  padding: 19px;
}

.apple-btn{
  height: 51px;
  width: 100%;
  background-color: #1C1C1C !important;
  border-radius: 15px !important;
  outline: none;
  border: none !important;
  font-weight: 600 !important;
  font-family: 'Poppins';
}

.apple-btn-inner-container {
  width: 100%;
  height: 100%;
}

.go-back-btn {
  height: 51px;
  width: 100%;
  background-color: #454545 !important;
  border-radius: 15px !important;
  border: none !important;
  margin-top: 23px;
  font-weight: 600 !important;
  font-family: 'Poppins';
}

.apple-footer{
  margin: 0;
  margin-top: 23px;
  text-align: justify;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  line-height: 14px;
  color: #828A9A;
}

.stripe-payment-amount-container{
  margin: 15px 0;
  display: flex;
}

.stripe-payment-offer-container{
  background-color: green;
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px !important;
  display: flex;
}

.stripe-promo-text{
  font-size: 16px;
  font-family: 'Poppins';
  font-style: italic;
  &.underline { 
   text-decoration: underline; 
  }
  &.left { 
   margin-left: 5px;
  }
  &.normalStyle { 
   font-style: normal !important;
  }
}
.remove-promo-btn{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0;
}
