.background-container {
  padding: 0 !important;

  .container-col {
    padding: 8px;
  }

  .default-background {
    cursor: pointer;
    width: 100%;
    max-width: 122px;
    height: 145px;
    background: linear-gradient(180deg, #0A85F7 0%, #00D1FF 100%);
    border: 1px solid #9D9D9D;
    border-radius: 21px;
    margin: auto;
  }

  .select-background-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 6px;
  }

  .image-video-background {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 122px;
    height: 145px;
    background: linear-gradient(180deg, #EDEDED 0%, #1E1E1E 0.01%, #080908 100%);
    border: 1px solid #9D9D9D;
    border-radius: 21px;
    margin: auto;

    input {
      &#file {
        display: inline-block;
        width: 100%;
        padding: 144px 0 0 0;
        height: 0;
        cursor: pointer;
      }

      &::-webkit-file-upload-button {
        display: none;
      }
    }
  }

  .photo-background-icon {
    background: linear-gradient(180deg, #EDEDED 0%, #1E1E1E 0.01%, #080908 100%);
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 122px;
    height: 145px;
    border: 1px solid #9D9D9D;
    border-radius: 21px;
    margin: auto;
  }

  .crome-color-picker {
    width: 180px !important;
  }

  .box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px #00000040;
    padding: 8px;
    position: absolute;
    z-index: 102;
    max-width: 312px;
    top: -290px;
    left: 25px;

    .df {
      display: flex;
    }

    .ac {
      align-items: center;
    }

    .jc {
      justify-content: center;
    }

    .jfe {
      justify-content: flex-end;
    }

    .jsb {
      justify-content: space-between;
    }

    .ps-rl {
      position: relative;
    }

    .c-cross {
      cursor: crosshair;
    }


    .c-resize {
      cursor: ew-resize;
    }

    .opacity-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px
    }

    .opacity-bg {
      display: flex;
      flex-wrap: wrap;
      border-radius: 10px;
      overflow: hidden
    }

    .handle {
      position: absolute;
      border: 2px solid white;
      border-radius: 50%;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
      width: 18px;
      height: 18px;
      z-index: 1000;
    }

    .gradient-handle-wrap {
      position: absolute;
      z-index: 10000;
      padding-left: 2px;
      padding-right: 2px;
      top: 4px
    }

    .gradient-handle {
      border: 2px solid white;
      border-radius: 50%;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
      width: 18px;
      height: 18px;
    }

    .npe {
      pointer-events: none;
    }

    .degree-input {
      height: 24px;
      border-radius: 4px;
      border: none;
      outline: none;
      text-align: center;
      width: 34px;
      font-weight: 500;
      color: rgb(50, 49, 54);
      font-size: 13px;
      background: transparent;
    }

    .input-wrap {
      height: 32px;
      border-radius: 6px;
      border: 1px solid #bebebe;
      width: 100%;
      padding: 2px;

      outline: none;
      color: black;
      font-weight: 400;
      text-align: center;
    }

    .input-label {
      text-align: center;
      line-height: 1.2;
      font-weight: 700;
      color: rgb(86, 86, 86);
      font-size: 11px;
    }

    .control-btn {
      padding-left: 8px;
      padding-right: 8px;
      height: 100%;
      line-height: 1;
      border-radius: 4px;
      font-weight: 700;
      color: rgb(86, 86, 86);
      font-size: 12px;
      height: 24px;
      transition: all 160ms ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bar-wrap {
      width: 330px;
      margin-left: -18px;
      padding: 3px;
      margin-top: 2px
    }

    .bar-wrap-inner {
      width: 324px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .control-btns-wrap {
      height: 28px;
      background: #e9e9f5;
      border-radius: 6px;
      padding: 2px;
    }

    .input-dropdown {
      position: absolute;
      right: -2px;
      top: 34px;
      padding: 5px;
      background: #e9e9f5;
      z-index: 100000000;
      border-radius: 6px;
      box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, .25)
    }

    .input-dropdown::before {
      position: absolute;
      content: '';
      right: 12.5px;
      top: -3px;
      width: 9px;
      height: 9px;
      background: #e9e9f5;
      transform: rotate(45deg);
      z-index: 0;
      box-shadow: -1px -2px 8px rgba(0, 0, 0, .15);
    }

    input {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }

  }
}
.circular-progress-bar{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
}