@import './palette.scss';

@mixin light-container ($class){

    border: $theme-light-border;
    border-radius: $border-radius;
    background-color: $theme-light-background;
    color: black;
    font-weight: bold;
    
}

@mixin dark-container {

}

@mixin theme-dark {
    @include dark-container;
}

@mixin theme-light {
    @include light-container;
}