.switch-btn {
  width: 100%;
  height: 32px;
  font-size: 15px !important;
  border-radius: 9px !important;
  padding: 0 !important;
  margin-bottom: 10px;
}

.heading-text-container {
  margin-top: 20px;
  margin-bottom: 14px;
}

.heading-text {
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 700;
  line-height: 25.03px;
  margin: 0;

  &.light {
    font-weight: 400;
    margin-right: 5px;
  }
  &.veryLight {
    font-weight: 300;
    font-size: 18px;
  }
  &.underline {
    text-decoration: underline;
  }
  &.redColor {
    color: #BF3131;
  }
}

.manage-subscription {
  font-size: 17px;
  font-family: 'Poppins';
  font-weight: 300;
  margin: 0;
  margin-top: 14px;
  margin-bottom: 20px;

  &.underline {
    text-decoration: underline;
  }
}

.card-list-container {
  // background-color: red;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #8E9195;
  height: 64px;
  margin-top: 15px;
  padding: 12px;

  &.defaultMethod {
    border: 1px solid #416AA7;
  }
}

.edit-icons {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.default-btn {
  background-color: red;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #414344;
  color: #fff;
  font-size: 11px !important;
}

.four-dot {
  margin-left: 8px;
  margin-right: 8px;
}

.user-details {
  font-size: 15px;
  font-weight: 400;
  line-height: 15.64px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 40%;
  color: #B3B3B3;

  &.user-info {
    width: 70%;
  }
}

.edit-icon {
  margin-right: 4px;
}

.confirm-delete-content-modal {
  background-color: #1D1D1F !important;
  border-radius: 20px !important;
  margin: 10px;
}

.confirm-btn-container {
  margin-top: 20px;
}

.confirmation-btn {
  width: 45%;
  height: 40px;
  border-radius: 10px !important;
  font-family: 'Poppins';
}

.moreOptions {
  padding: 2px;
}

.promo-modal-input-form-container {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    color: lightgrey;
    background-color: #464646 !important;
    border-radius: 6px !important;
    border-width: 1px !important;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    &:focus {
      color: lightgrey !important;
      box-shadow: none;
      border: double 1px transparent;
      border-radius: 6px !important;
      background-image: linear-gradient(#464646, #464646), 
                        linear-gradient(to bottom, #567df4, #6200ee);
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }
  }

  .input-group-append {
    padding: 10px;
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 10;
  }
 
}

.promo-modal-input-form {
  height: 3rem;
  margin-top: 1.1rem !important;
}