.sign-in__btn {
  background-color: #567df4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 !important;
  border-radius: 12px !important;
  width: 48%;
  margin-right: 0.75rem !important;

  svg {
    margin-left: 15px;
  }
}
